import React from 'react'
import {Helmet} from 'react-helmet'
import {Link} from 'gatsby'
import Layout from 'layouts/layout'
import ColumnLayout from 'layouts/column-layout'

import styles from 'components/inline-link.module.scss'

// Page sections
import KeyDocuments from '../../about-helios-underwriting/overview/_key-documents'

class SellYourLloydsVehicleEnquiryConfirmation extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Sell your Lloyd’s vehicle | Helios Underwriting</title>
        </Helmet>
        <ColumnLayout aside={<KeyDocuments />}>

          <div className="u-flex u-flex-col u-justify-between u-h-full">

            <section className="padding-md">
              <h1 className="h1 mb-40">
                Thank you
              </h1>

              <div className="u-color-emperor">
                <p className="lead">
                  We will contact you in due course.
                </p>
                <p>
                  Find out more about our current
                  {' '}
                  <Link className={styles.link} to="/about-helios-underwriting/portfolio-and-capacity">
                    portfolio and capacity
                  </Link>.
                </p>
              </div>

            </section>

          </div>

        </ColumnLayout>
      </Layout>
    )
  }
}

export default SellYourLloydsVehicleEnquiryConfirmation